import { getAutocompleteApi, getAutocompleteStoreApi } from './google-api';

export const getCityByZipCode = async (zipCode: number, country: string) => {
    try {
        const dawaResponse = await fetch(`https://api.dataforsyningen.dk/postnumre/${zipCode}`);

        if (!dawaResponse.ok) {
            console.warn(`DAWA request failed with status ${dawaResponse.status}`);
            throw new Error(`DAWA request failed: ${dawaResponse.statusText}`);
        }

        const dawaData = await dawaResponse.json();
        return dawaData?.navn || null;
    } catch (error) {
        console.warn('DAWA request failed, falling back to getAutocompleteApi', error);
    }

    // Fallback to getAutocompleteApi
    const service = await getAutocompleteApi();
    if (!service) {
        console.warn('getAutocompleteApi service not available.');
        return null;
    }

    const predictions = await service.getPlacePredictions({
        input: String(zipCode),
        componentRestrictions: { country },
        types: ['(regions)'],
    });

    return predictions?.predictions?.[0]?.terms?.[1]?.value || null;
};

export const getCoordinatesByZipOrCity = async (zipCodeOrCity: string, country: string) => {
    const service = getAutocompleteStoreApi();

    if (service) {
        const t = await service.geocode({
            address: `${String(zipCodeOrCity)}`,
            componentRestrictions: {
                country: country,
            },
        });

        return {
            lat: t.results[0].geometry.location.lat(),
            lng: t.results[0].geometry.location.lng(),
        };
    }
};
