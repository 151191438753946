import { theme } from '$theme';
import { StylesConfig } from 'react-select';
import { CountryCodeOption } from './types';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export type ReactSelectCustomStylesProps = {
    isInvalid?: boolean;
};

export const customStyles = ({
    isInvalid,
}: ReactSelectCustomStylesProps): StylesConfig<CountryCodeOption> => ({
    container: (provided) => ({
        ...provided,
        ...theme.mixins.useTextStyle('formInput'),
        display: 'flex',
        position: 'static',
        flex: '0 0 90px',
        minWidth: 0,
        borderRight: `1px solid ${isInvalid ? theme.colors.negative : theme.colors.black}`,
    }),

    menu: (provided) => ({
        ...provided,
        marginTop: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: 'none',
        border: `1px solid ${isInvalid ? theme.colors.negative : theme.colors.black}`,
        left: '-1px',
        width: 'calc(100% + 1.5px)',
    }),

    menuList: (provided) => ({
        ...provided,
    }),

    placeholder: (provided) => ({
        ...provided,
    }),

    input: (provided) => ({
        ...provided,
        maxWidth: '100%',
        overflow: 'hidden',
    }),

    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),

    dropdownIndicator: (provided) => ({
        ...provided,
        color: theme.colors.black,
    }),

    valueContainer: (provided) => ({
        ...provided,
        paddingLeft: theme.space[3],
        paddingRight: 0,
    }),

    control: (provided) => ({
        ...provided,
        border: 'none',
        backgroundColor: 'transparent',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        outline: 'none',
        boxShadow: 'none',
        width: '100%',
        overflow: 'hidden',
    }),

    option: (provided, { isDisabled, isFocused, isSelected }) => ({
        ...provided,
        padding: theme.space[3],
        color: theme.colors.black,
        ':last-of-type': {
            borderBottomLeftRadius: theme.sizes.cornerRadius,
            borderBottomRightRadius: theme.sizes.cornerRadius,
        },
        ':not(:last-of-type)': {
            borderBottom: `1px solid ${theme.colors.brownLight}`,
        },
        backgroundColor: isDisabled
            ? undefined
            : isSelected
              ? theme.colors.grey20
              : isFocused
                ? theme.colors.grey10
                : undefined,

        ':active': {
            ...provided[':active'],
            backgroundColor: !isDisabled
                ? isSelected
                    ? theme.colors.grey20
                    : theme.colors.grey20
                : undefined,
        },
    }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;

        return {
            ...provided,
            opacity,
        };
    },
});

export const StyledContainer = styled.div(() => ({
    // ...
}));

interface StyledPhoneElementProps {
    isError: boolean;
    isSingleField: boolean;
    isMenuOpen: boolean;
}

export const StyledPhoneElement = styled.div<StyledPhoneElementProps>(
    ({ theme, isError, isMenuOpen }) => ({
        position: 'relative',
        display: 'flex',
        border: `1px solid ${isError ? theme.colors.negative : theme.colors.black}`,
        color: isError ? theme.colors.negative : theme.colors.black,
        borderRadius: theme.sizes.cornerRadius,
        borderBottomLeftRadius: isMenuOpen ? 0 : theme.sizes.cornerRadius,
        borderBottomRightRadius: isMenuOpen ? 0 : theme.sizes.cornerRadius,
        ':has(:focus-visible)': {
            boxShadow: theme.shadows.accessibility,
        },
    }),

    ifProp('isError', ({ theme }) => ({
        [`${StyledLabel}`]: {
            color: theme.colors.negative,
        },
    })),

    ifProp({ isSingleField: false }, ({ theme }) => ({
        [`${StyledLabel}`]: {
            backgroundColor: theme.colors.white,
            paddingLeft: theme.space[1],
            paddingRight: theme.space[1],
            transform: 'translateY(-30px)',
            ...theme.mixins.useTextStyle('formsLabelActive'),
        },
    })),
);

export const StyledInput = styled.input(({ theme }) => ({
    ...theme.mixins.useTextStyle('formInput'),
    border: 'none',
    color: theme.colors.black,
    borderRadius: 'inherit',
    height: theme.metrics.medium,
    lineHeight: theme.lineHeights.single,
    boxSizing: 'border-box',
    display: 'block',
    flexGrow: 1,
    outline: 'none',
    padding: `0 ${theme.space[3]}`,
    '&:disabled': {
        opacity: 0.8,
        cursor: 'not-allowed',
    },

    '&:empty:placeholder-shown::placeholder': {
        color: 'transparent',
    },

    '&:focus:empty::placeholder': {
        color: theme.colors.grey70,
    },

    '&:not(:placeholder-shown) ~ label, &:focus ~ label': {
        backgroundColor: theme.colors.white,
        paddingLeft: theme.space[1],
        paddingRight: theme.space[1],
        transform: 'translateY(-30px)',
        ...theme.mixins.useTextStyle('formsLabelActive'),
    },
}));

export const StyledLabel = styled.label(({ theme }) => ({
    ...theme.mixins.useTextStyle('formsLabel'),
    position: 'absolute',
    top: '50%',
    left: theme.space[3],
    ...theme.mixins.useTextStyle('formsLabel'),
    transition: '0.1s transform, 0.1s width',
    color: theme.colors.black,
    transform: 'translateY(-50%)',
    maxWidth: `calc(100% - ${theme.space[3]} - ${theme.space[3]})`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transformOrigin: 'left top',
    pointerEvents: 'none',
}));

export const StyledOption = styled.p(() => ({
    display: 'flex',
    marginBottom: 0,
}));

export const StyledOptionCode = styled.span(({ theme }) => ({
    flex: `0 0 ${90 - parseInt(theme.space[3])}px`,
}));

export const StyledOptionLabel = styled.span(({ theme }) => ({
    flexGrow: 1,
    paddingLeft: theme.space[3],
    paddingRight: theme.space[3],
}));

export const StyledAdditionalContent = styled.span(({ theme }) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    svg: {
        overflow: 'visible',
    },
    button: {
        borderTopRightRadius: `calc(${theme.sizes.cornerRadius} - 2px)`,
        borderBottomRightRadius: `calc(${theme.sizes.cornerRadius} - 2px)`,
    },
}));
